import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import CookieUtil from '../utils/CookieUtil';
import api from './API';

export const submitDriveOrder = (data) => {
    const tgid = CookieUtil.loadTelegramId();
    const amount = CookieUtil.loadPaymentAmount() || 500;
    const amount_fast = CookieUtil.loadPaymentAmountFast() || 1050;
    const newData = Object.assign({}, data, {tgid, amount, amount_fast});
    return api.post(
        `${ApiEndpointConstants.ORDER}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateDriveOrder = (data) => {
    const tgid = CookieUtil.loadTelegramId();
    const newData = Object.assign({}, data, {tgid});
    return api.post(
        `${ApiEndpointConstants.ORDER_UPDATE}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateOrderStatus = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_STATUS_UPDATE}`,
        params,
    );
}